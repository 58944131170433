/* eslint-disable no-console */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { flatten } from "flat";
import { writeToString } from "@fast-csv/format";

export const query = graphql`
  query csvQuery($langKey: String!) {
    content: allMarkdownRemark(filter: { fields: { langKey: { eq: $langKey } } }) {
      nodes {
        i18n: frontmatter {
          ...AboutMarkdownRemarkFragment
          ...TimeLineMarkdownRemarkFragment
          ...BundleDetailsMarkdownRemarkFragment
          ...CallbackMarkdownRemarkFragment
          ...CleaningMarkdownRemarkFragment
          ...ClientsMarkdownRemarkFragment
          ...ContactMarkdownRemarkFragment
          ...FooterMarkdownRemarkFragment
          ...LandingsNavigationMarkdownRemarkFragment
          ...NavbarMarkdownRemarkFragment
          ...NotFoundMarkdownRemarkFragment
          ...PaintingDetailsMarkdownRemarkFragment
          ...PortfolioMarkdownRemarkFragment
          ...PrivacyMarkdownRemarkFragment
          ...RenovationDetailsMarkdownRemarkFragment
          ...RotDetailsMarkdownRemarkFragment
          ...SendmailMarkdownRemarkFragment
          ...ServicesMarkdownRemarkFragment
          ...TermsMarkdownRemarkFragment
          ...TopMarkdownRemarkFragment
        }
        fields {
          fileName
          directoryName
        }
      }
    }
  }
`;

const IndexPage = ({ data, pageContext }) => {
  console.dir(pageContext);

  const convertToKeys = (node) => {
    // TODO: recursive node properties processing
    // https://stackoverflow.com/questions/15690706/recursively-looping-through-an-object-to-build-a-property-list/35342775
    // https://github.com/hughsk/flat
    // alternative: https://github.com/blackflux/object-scan
    const allKeys = flatten(node);

    return Object.keys(allKeys).map((key) => ({ key, value: allKeys[key] }));
  };

  const dataForCsv = data.content.nodes.flatMap((node) => {
    return convertToKeys(node.i18n)
      .filter((pair) => pair.value !== null)
      .map((pair) => ({
        directory: node.fields.directoryName,
        filename: node.fields.fileName,
        key: pair.key,
        value: pair.value,
        translation: "",
      }));
  });

  const [csv, setCsv] = useState("");
  useEffect(() => {
    async function getCsvString() {
      const csvString = await writeToString(dataForCsv, { headers: true });
      setCsv(csvString);
    }

    if (csv === "") {
      getCsvString().then(() => {});
    }
  }, [dataForCsv, csv]);

  const handleDownload = () => {
    if (csv === "" || typeof csv !== "string") return;

    const blob = new Blob([csv], { type: "text/csv" });
    const csvURL = window.URL.createObjectURL(blob);

    const tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "english2swedish.csv");
    tempLink.click();
  };

  return (
    <>
      <button type="button" onClick={handleDownload}>
        Download CSV
      </button>
      <table>
        <thead>
          <tr>
            <th>Directory</th>
            <th>File</th>
            <th>Key</th>
            <th>Content</th>
            <th>Translation</th>
          </tr>
        </thead>
        <tbody>
          {dataForCsv.map((row) => (
            <tr key={`${row.directory}-${row.filename}-${row.key}`}>
              <td>{row.directory}</td>
              <td>{row.filename}</td>
              <td>{row.key}</td>
              <td>{row.value}</td>
              <td style={{ width: "100px" }}>{row.translation}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
};

IndexPage.defaultProps = {
  pageContext: {
    langKey: "en",
    defaultLang: "en",
    langTextMap: {},
  },
};

export default IndexPage;
